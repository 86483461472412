<template>
  <div class="common-bg">
    <van-nav-bar
      title="原始股权查询"
      left-arrow
      @click-left="onClickLeft"
    />
    <div v-if="list.length > 0">
        <div v-for="item in list" :key="item.id">
          <div class="stock-card">
            <div class="card-li">
              <span class="text-grey">名称：</span>
              <span class="font-weight">{{item.moneyType}}</span>
            </div>
            <div class="card-li">
              <span class="text-grey">数量：</span>
              <span class="font-weight">{{item.money}}股</span>
            </div>
            <div class="card-li">
              <span class="text-grey">时间：</span>
              <span class="font-weight">{{item.createTime}}</span>
            </div>
            <div class="card-li">
              <span class="text-grey">备注：</span>
              <span class="font-weight">{{item.note}}</span>
            </div>
          </div>
        </div>
    </div>
    <div class="text-center padded-t-15" v-else>
      暂无记录
    </div>
  </div>
</template>

<script>
import { getGuQuan } from '@/utils/api.js'
export default {
  name: 'currencyDetail',
  data () {
    return {
      list: []
    }
  },
  created () {
    this.initData()
  },
  methods: {
    initData () {
      getGuQuan().then(res => {
        this.list = res.data
      })
    },
    onClickLeft () {
      this.$router.go(-1)
    }
  }
}
</script>

<style lang="scss" scoped>
  .stock-card {
    padding: 15px;
    background: hsla(0,0%,100%,.7);
    border-radius: 8px;
    font-size: 14px;
    margin: 10px;
    .card-li {
      padding: 3px 0;
    }
  }
</style>
